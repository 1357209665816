const LOCATION_COOKIE = "location";

type Location = { lat: number; long: number } | null;

export default function useLocationState() {
  const location = useStatefulCookie<Location>(LOCATION_COOKIE);

  // Return the cookie
  return location;
}
